export const authApi = api => ({
  register({ email, password, phone_number, given_name, family_name }) {
    return api.post("/auth/register", {
      email,
      password,
      phone_number,
      given_name,
      family_name
    });
  },
  confirm({ email, code }) {
    return api.post("/auth/confirm", {
      email,
      confirmation_code: code
    });
  },
  login({ email, password }) {
    return api.post("/auth/login", {
      email,
      password
    });
  },
  logout() {
    return api.get("/auth/logout");
  },
  forgotPassword({ email }) {
    return api.post("/auth/forgot-password", {
      username: email
    });
  },
  resetPassword({ email, code, password }) {
    return api.post("/auth/confirm-reset", {
      username: email,
      confirmation_code: code,
      new_password: password
    });
  },
  refreshToken({ email, refresh_token }) {
    return api.post("/auth/refresh-token", {
      email,
      refresh_token
    }).catch(error => {
      console.error('Token refresh failed:', error);
      // Handle token refresh failure (e.g., redirect to login)
      throw error;
    });
  }
});
