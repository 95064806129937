import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { getQueryParamWithKey } from "../../utils/url";
import DOMPurify from "dompurify";
import {
  IconBookmark,
  IconBookmarkFilled,
  IconDownload,
  IconExternalLink
} from "@tabler/icons-react";
import { convertToHTML, removeAllStylesFromText } from "../../utils/html";
import Markdown from "react-markdown";
import { useReactToPrint } from "react-to-print";
import { sendEvent } from "../../utils/event";

const DocDetail = () => {
  const { type } = useParams();
  const contentRef = useRef();
  const s3Url = getQueryParamWithKey("s3Url");
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState({
    summary: true,
    content: true,
    bookmark: true
  });
  const [params, setParams] = useState({
    documentId: null,
    documentSource: null
  });
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [summary, setSummary] = useState(null);
  const [title, setTitle] = useState(
    type === "mevzuat" ? "Mevzuat" : "İçtihat"
  );
  const navigate = useNavigate();

  const addBookmark = async () => {
    await api.chat
      .addFavorite({
        documentId: params.documentId,
        documentSource: params.documentSource
      })
      .then(() => {
        setIsBookmarked(true);
      })
      .catch(err => console.error(err));
  };

  const removeBookmark = async () => {
    await api.chat
      .removeFavorite({
        documentId: params.documentId,
        documentSource: params.documentSource
      })
      .then(() => {
        setIsBookmarked(false);
      })
      .catch(err => console.error(err));
  };

  const openNewTab = () => {
    window.open(location.href, "_blank");
  };

  const downloadDocument = useReactToPrint({
    contentRef,
    pageStyle: {
      margin: 20
    }
  });

  useEffect(() => {
    const init = async () => {
      let documentId = "";
      let documentSource = "";

      try {
        if (type === "mevzuat") {
          const response = await api.content.getMevzuatContent(s3Url);

          if (response) {
            const getData = response.data;
            documentId = getData.id;
            documentSource = getData.source;
            const cleanHTML = DOMPurify.sanitize(getData.content);
            setContent(cleanHTML);
            setIsLoading(prevState => ({
              ...prevState,
              content: false
            }));
          }
        } else {
          const response = await api.content.getIctihatContent(s3Url);

          if (response) {
            const {
              kararMetni,
              daire,
              kararTarihi,
              esasNo,
              kararNo,
              id,
              source
            } = response.data;
            documentId = id;
            documentSource = source;
            const htmlContent = `
              <html>
                <head>
                  <title>İçtihat: ${daire} - ${kararTarihi}</title>
                </head>
                <body class="no-scrollbar">
                  <div class="meta prose prose-base prose-gray dark:prose-invert no-scrollbar">
                    <p><strong>Daire:</strong> ${daire}</p>
                    <p><strong>Karar Tarihi:</strong> ${kararTarihi}</p>
                    <p><strong>Esas No:</strong> ${esasNo}</p>
                    <p><strong>Karar No:</strong> ${kararNo}</p>
                  </div>
                  <div class="prose prose-base prose-gray overflow-scroll leading-6 dark:prose-invert prose-h1:text-xl prose-h2:text-lg prose-p:mb-0 prose-p:mt-1.5 prose-ol:mt-0 prose-ol:flex prose-ol:flex-col prose-table:text-[length:inherit] mt-4">${convertToHTML(kararMetni)}</div>
                </body>
              </html>`;
            const cleanHTML = DOMPurify.sanitize(htmlContent);
            setContent(cleanHTML);
            setIsLoading(prevState => ({
              ...prevState,
              content: false
            }));
          }
        }

        const favoriteResponse = await api.chat.getFavorites();
        if (favoriteResponse) {
          const { favorites } = favoriteResponse.data;
          const isBookmarkedStatus = favorites.some(
            favorite =>
              favorite.document_id === documentId &&
              favorite.document_source === documentSource
          );
          setParams({ documentId, documentSource });
          setIsBookmarked(isBookmarkedStatus);
          setIsLoading(prevState => ({
            ...prevState,
            bookmark: false
          }));
        }

        const summaryResponse =
          await api.doc[
            type === "mevzuat" ? "mevzuatSummarize" : "ictihatSummarize"
          ](s3Url);

        if (summaryResponse) {
          const { data } = summaryResponse;
          if (data.summary) {
            setSummary(data.summary);
            if (data.title) {
              setTitle(data.title);
            }
            setIsLoading(prevState => ({
              ...prevState,
              summary: false
            }));
          }
        }

        sendEvent(`view_${type}_document`, {
          document_id: documentId
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();

    return () => {
      setContent(null);
      setIsLoading({
        content: true,
        summary: true,
        bookmark: true
      });
      setParams({ documentId: null, documentSource: null });
      setIsBookmarked(false);
    };
  }, []);

  const { t } = useTranslation();
  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <div className="mb-2 flex items-center justify-between text-gray-700 md:flex-col dark:text-white">
        <h1
          className={"mb-4 font-title text-2xl font-semibold text-primary-500"}
        >
          {title}
        </h1>

        <div className="absolute right-8 top-4 z-10 flex items-center justify-end gap-1 md:right-2 md:top-1">
          <button
            title={isBookmarked ? "Kaydetme" : "Kaydet"}
            onClick={isBookmarked ? removeBookmark : addBookmark}
            className="flex min-h-10 min-w-10 cursor-pointer items-center justify-center rounded-full font-display text-gray-500 transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 hover:dark:bg-gray-800 hover:dark:text-gray-300"
          >
            {!isLoading.bookmark &&
              (isBookmarked ? (
                <IconBookmarkFilled size={24} stroke={1.25} />
              ) : (
                <IconBookmark size={24} stroke={1.25} />
              ))}
          </button>
          <button
            title="Yeni Sekmede Aç"
            onClick={openNewTab}
            className="flex min-h-10 min-w-10 cursor-pointer items-center justify-center rounded-full font-display text-gray-500 transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 hover:dark:bg-gray-800 hover:dark:text-gray-300"
          >
            <IconExternalLink size={24} stroke={1.25} />
          </button>
          <button
            title="Dökümanı İndir"
            onClick={downloadDocument}
            className="flex min-h-10 min-w-10 cursor-pointer items-center justify-center rounded-full font-display text-gray-500 transition duration-300 ease-in-out hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 hover:dark:bg-gray-800 hover:dark:text-gray-300"
          >
            <IconDownload size={24} stroke={1.25} />
          </button>
        </div>
      </div>

      <div className="mx-auto w-full max-w-4xl">
        <section className={"flex min-w-full flex-col"} ref={contentRef}>
          <div className="mb-2 flex items-center justify-between text-gray-700 dark:text-white">
            <h1
              className={"font-display text-xl font-semibold text-primary-500"}
            >
              {type === "mevzuat" ? "Mevzuat" : "Karar"} Özeti
            </h1>
          </div>
          {isLoading.summary ? (
            <>
              <div className="animated-text mb-8 animate-typing text-left text-base font-medium">
                Size kolaylık sağlaması için belgenin özeti oluşturuluyor. Bu
                işlem birkaç dakika sürebilir, lütfen bekleyiniz...{" "}
              </div>
            </>
          ) : (
            <article className="prose prose-base prose-gray mb-8 dark:prose-invert">
              <Markdown>{summary}</Markdown>
            </article>
          )}
          {type === "ictihat" && !isLoading.content && (
            <div className="hide-print mb-8 flex items-center justify-start md:flex-col md:gap-3 md:text-center">
              <button
                onClick={() => navigate(`/analyze?s3Url=${s3Url}`)}
                className="min-w-[250px] max-w-[350px] rounded-full border border-primary-500 px-4 py-2 font-display text-primary-500 transition-all hover:bg-primary-500 hover:text-white"
              >
                Günceli var mı?
              </button>
              <div className="ml-4 font-sans text-sm text-primary-500">
                Yapay zeka ile bu kararın daha güncellerini arayabilir, benzer
                kararlar arasında bir değerlendirme yapabilirsiniz.
              </div>
            </div>
          )}
          <div className="mb-2 flex items-center justify-between text-gray-700 dark:text-white">
            <h1
              className={"font-display text-xl font-semibold text-primary-500"}
            >
              {type === "mevzuat" ? "Mevzuat" : "Karar"} Detayı
            </h1>
          </div>
          {isLoading.content ? (
            <>
              <div className="animated-text mb-2 animate-typing text-base font-medium">
                Belge içeriği alınıyor… Lütfen bekleyiniz.
              </div>
            </>
          ) : (
            <article
              className="no-scrollbar prose prose-base prose-gray mb-10 overflow-scroll leading-6 dark:prose-invert prose-h1:text-xl prose-h2:text-lg prose-p:mb-0 prose-p:mt-1.5 prose-ol:mt-0 prose-ol:flex prose-ol:flex-col prose-table:text-[length:inherit] md:mt-6 md:px-4 md:py-8"
              dangerouslySetInnerHTML={{
                __html: removeAllStylesFromText(content)
              }}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default DocDetail;
