import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SettingsSidebar } from "../components";
import settingsMenu from "../constants/settingsMenu";

const SettingsLayout = () => {
  const location = useLocation();

  const activeItem = useMemo(() => {
    return settingsMenu.find(
      item => `/settings/${item.path}` === location.pathname
    );
  }, [location]);

  return (
    <div className="flex h-screen flex-col space-x-0 space-y-4">
      <div className="min-w-44">
        <SettingsSidebar activePage={location.pathname} />
      </div>
      <div className="no-scrollbar flex max-h-screen w-full flex-1 flex-col">
        <h1 className="font-display border-b border-gray-100 pb-2 text-xl font-bold font-display mt-4 text-gray-900 dark:border-gray-800 dark:text-gray-50 md:mt-2">
          {activeItem.name}
        </h1>
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
