import React from "react";
import { useAuth } from "context/auth.context";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input, Button } from "components";
import { emailPattern } from "constants/patterns";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useAuth();
  const [searchParams] = useSearchParams();
  const confirm = searchParams.get("confirm") === "success";
  const resetPassword = searchParams.get("reset") === "true";
  const [isError, setIsError] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);
    setIsError(false);
    await api.auth
      .login({
        email: submitData.email,
        password: submitData.password
      })
      .then(async response => {
        const { data } = response;
        await setUser({
          token: data.AccessToken
        });
        setTimeout(async () => {
          const getUserProfile = await api.user.getProfile();
          if (getUserProfile) {
            navigate("/");
            setUser({
              token: data.AccessToken,
              ...getUserProfile.data
            });
          }
        }, 300);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center text-center md:px-5">
      {/* <h1 className="mt-10 mb-6 font-display text-4xl font-medium text-gray-950 dark:text-white">
        Hesabınıza giriş yapın
      </h1> */}
      <div className="w-full px-4 md:px-0">
        {confirm && (
          <div className="mb-4 py-3 text-center font-sans font-medium text-green-700">
            <strong className="font-bold">Tebrikler!</strong> Hesabınız başarıyla
            onaylandı. Şimdi giriş yapabilirsiniz.
          </div>
        )}
        {resetPassword && (
          <div className="mb-4 py-3 text-center font-sans font-medium text-green-700">
            Şifreniz başarıyla sıfırlandı. Yeni şifrenizle giriş yapabilirsiniz.
          </div>
        )}
        <form className="mx-auto max-w-96 mt-4" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={t("_auth.email")}
            name="email"
            error={errors.email}
            rules={{ required: true, pattern: emailPattern }}
            register={register}
            className="mb-4"
            placeholder={t("_auth.email")}
          />
          <Input
            label={t("_auth.password")}
            name="password"
            error={errors.password}
            rules={{ required: true }}
            type="password"
            className="mb-2"
            register={register}
            placeholder={t("_auth.password")}
          />
          <div className={"mb-4 text-right"}>
            <span
              className="cursor-pointer text-sm font-sans font-medium text-gray-600 dark:text-gray-300"
              onClick={() => navigate("/auth/forgot-password")}
            >
              Şifremi unuttum
            </span>
          </div>
          <Button type="submit" isLoading={isLoading} className="font-display">
            {t("_auth.login")}
          </Button>
          {isError && (
            <div className="mt-4 py-3 text-center font-sans text-red-700">
              Kullanıcı adı veya şifre hatalı. Lütfen tekrar deneyin.
            </div>
          )}
          <div className="mt-8 text-center text-sm font-sans text-gray-600 dark:text-gray-300">
            Hesabınız yok mu?{" "}
            <span
              onClick={() => navigate("/auth/register")}
              className="cursor-pointer font-sans text-primary-600"
            >
              Yeni üyelik oluşturun
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
