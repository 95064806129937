import {
  IconUser,
  IconShieldLock,
  IconCreditCard,
  IconCalendarMonth,
  IconDatabase
} from "@tabler/icons-react";

export default [
  {
    name: "Profil",
    Icon: IconUser,
    path: "profile"
  },
  {
    name: "Güvenlik",
    Icon: IconShieldLock,
    path: "security"
  },
  {
    name: "Abonelik",
    Icon: IconCalendarMonth,
    path: "subscription"
  },
  {
    name: "Ödeme",
    Icon: IconCreditCard,
    path: "payment"
  },
  {
    name: "Data",
    Icon: IconDatabase,
    path: "data"
  }
];
