import React, { useState } from "react";
import { useAuth } from "../../context/auth.context";
import { Button, ConfirmModal, Input, MaskInput } from "../../components";
import { emailPattern } from "../../constants/patterns";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import toast from "react-hot-toast";
import SECURITY_LIST from "constants/security_list";
import { useScreenDetector } from "hooks/use-screen-detector";

const Security = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [securityList, setSecurityList] = useState(SECURITY_LIST);
  const [confirmModal, setConfirmModal] = useState(false);
  const { setUser } = useAuth();
  const { isMobile } = useScreenDetector();

  const deleteAccount = () => {
    api.user
      .deleteProfile()
      .then(() => {
        toast.success("Profil başarıyla silindi");
        setUser({});
        localStorage.removeItem("user");
      })
      .catch(() => {
        toast.error("Bir hata oluştu.");
      });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm({
    defaultValues: {}
  });

  const onSubmit = async submitData => {
    console.log(submitData);
    setIsLoading(true);
    if (submitData.new_password === submitData.confirm_password) {
      await api.user
        .changePassword(submitData)
        .then(() => {
          toast.success("Değişiklikler başarıyla kaydedildi.");
        })
        .catch(() => {
          toast.error("Mevcut şifrenizi yanlış girdiniz.");
        });
    } else {
      toast.error("Yeni şifreler eşleşmiyor.");
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="flex w-full flex-col justify-center">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          {securityList.map((item, index) => (
            <div
              key={index}
              className={`flex w-full justify-between border-gray-100 py-6 dark:border-gray-800 md:flex-col ${index !== securityList.length - 1 ? "border-b" : ""}`}
            >
              <div className="w-1/4 text-base font-semibold text-gray-800 dark:text-gray-100 md:mb-2 md:w-full">{item.name}</div>
              <div className="flex flex-1 justify-between">
                <Input
                  label=""
                  name={item.value}
                  error={errors[item.value]}
                  rules={{
                    required: item.isRequired,
                    minLength: 8
                  }}
                  type="password"
                  register={register}
                  className="max-w-[400px]"
                  placeholder={item.name}
                />

              </div>
            </div>
          ))
          }
          <div className="flex w-full justify-between border-b border-gray-100 pb-6 dark:border-gray-800">
            <div className="w-1/4 md:w-0"></div>
            <div className="flex flex-1 justify-between">
              <Button isFullWidth={isMobile} type="submit" isLoading={isLoading}>
                Şifreyi Değiştir
              </Button>
            </div>
          </div>
        </form >
        <div className="flex w-full justify-between border-b border-gray-100 py-6 dark:border-gray-800 md:flex-col">
          <div className="w-1/4 text-base font-semibold text-gray-800 dark:text-gray-100 md:mb-2 md:w-full">Hesabı Sil</div>
          <div className="flex flex-1 justify-between">
            <Button isFullWidth={isMobile} variant="danger" onClick={() => setConfirmModal(true)}>
              Hesabı Sil
            </Button>
          </div>
        </div>
      </div >
      <ConfirmModal
        closeModal={() => setConfirmModal(false)}
        isOpen={confirmModal}
        buttonTitle="Hesabı Sil"
        variant="danger"
        onClick={deleteAccount}
      />
    </>
  );
};

export default Security;
