import React from "react";
import { useNavigate } from "react-router-dom";
import settingsMenu from "../../constants/settingsMenu";

const SettingsSidebar = ({ activePage }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex w-full flex-1 flex-row space-x-1 overflow-scroll">
        {settingsMenu.map((item, index) => (
          <button
            onClick={() => {
              navigate(`/settings/${item.path}`);
            }}
            key={index}
            title={item.name}
            className={`font-display flex h-9 cursor-pointer items-center gap-2 rounded-full px-4 py-2 text-sm transition-colors hover:bg-gray-50 dark:text-white hover:dark:bg-gray-950  ${activePage === `/settings/${item.path}`
              ? "bg-gray-50 dark:bg-gray-950"
              : ""
              }`}
          >
            <item.Icon size={18} stroke={2} color="currentColor"></item.Icon>
            {item.name}
          </button>
        ))}
      </div>
    </>
  );
};

export default SettingsSidebar;
