import React, { useState } from "react"; // Add this line
import { Outlet } from "react-router-dom";
import "./assets/style/index.scss";
import { useEffect } from "react";
import { isDarkTheme } from "./utils/theme";
import api from "./services/api";
import { AuthProvider, useAuth } from "./context/auth.context";
import { Analytics } from "@vercel/analytics/react";
import { useAbortPendingRequests } from "./config";
import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from "./constants/google-analytics";
import { setUserId } from "./utils/event";

ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {
  const { user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  useAbortPendingRequests();

  useEffect(() => {
    const init = async () => {
      if (user && user.token) {
        await api.user
          .getProfile()
          .then(res => {
            const userData = res.data;
            userData.token = user.token;
            setUser(userData);
            setIsLoading(false);
            setUserId(userData.user_id);
          })
          .catch(err => {
            if (err.code === "ERR_CANCELED") {
              init();
              return;
            }
            setUser({});
            localStorage.removeItem("user");
            window.location.reload();
          });
      }
      setIsLoading(false);
    };
    if (isDarkTheme()) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    init();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Analytics />
      <Outlet />
    </>
  );
}

export default App;
