import React from "react";
import { AuthLayout, ChatLayout } from "layouts";
import Home from "./chat/Home";
import Login from "./auth/Login";
import Page404 from "./404/Page404";
import Welcome from "./auth/Welcome";
import Confirm from "./auth/Confirm";
import Terms from "./policies/Terms";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import PolicyLayout from "../layouts/policy.layout";
import Register from "./auth/Register";
import ForgotPassword from "./auth/Forgot";
import History from "./chat/History";
import Thread from "./chat/Thread";
import UnderConstruction from "./chat/UnderConstruction";
import Profile from "./settings/Profile";
import ResetPassword from "./auth/ResetPassword";
import SearchJurisprudence from "./search/Jurisprudence";
import SearchLegislation from "./search/Legislation";
import DocDetail from "./docs/Detail";
import Bookmarks from "./chat/Bookmarks";
import AllDocs from "./docs/AllDocs";
import Analyze from "./docs/Analyze";
import SettingsLayout from "../layouts/settings.layout";
import Security from "./settings/Security";
import App from "../App";
import { AuthProvider } from "../context/auth.context";

const routes = [
  {
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    children: [
      {
        path: "/",
        element: <ChatLayout />,
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: "history",
            element: <History />,
            handle: {
              title: "Geçmiş"
            }
          },
          {
            path: "chat/:id",
            element: <Thread />
          },
          {
            path: "ai-assistant",
            element: <UnderConstruction />
          },
          {
            path: "search-jurisprudence",
            element: <SearchJurisprudence />,
            handle: {
              title: "İçtihat Arama"
            }
          },
          {
            path: "search-legislation",
            element: <SearchLegislation />,
            handle: {
              title: "Mevzuat Arama"
            }
          },
          {
            path: "settings",
            element: <SettingsLayout />,
            handle: {
              title: "Ayarlar"
            },
            children: [
              {
                index: true,
                path: "profile",
                element: <Profile />
              },
              {
                path: "security",
                element: <Security />
              },
              {
                path: "subscription",
                element: <UnderConstruction />
              },
              {
                path: "payment",
                element: <UnderConstruction />
              },
              {
                path: "data",
                element: <UnderConstruction />
              }
            ]
          },
          {
            path: "new-thread",
            element: <Thread />
          },
          {
            path: "help",
            element: <UnderConstruction />
          },
          {
            path: "create-document",
            element: <UnderConstruction />
          },
          {
            path: "doc/:type",
            element: <DocDetail />
          },
          {
            path: "analyze",
            element: <Analyze />,
            handle: {
              title: "Doküman Analizi"
            }
          },
          {
            path: "bookmarks",
            element: <Bookmarks />,
            handle: {
              title: "Kaydedilenler"
            }
          },
          {
            path: "all-docs/:threadId/:responseId",
            element: <AllDocs />,
            handle: {
              title: "Tüm Kaynaklar"
            }
          }
        ]
      },
      {
        path: "/auth",
        element: <AuthLayout />,
        children: [
          {
            path: "welcome",
            element: <Welcome />
          },
          {
            path: "login",
            element: <Login />
          },
          {
            path: "register",
            element: <Register />
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />
          },
          {
            path: "confirm",
            element: <Confirm />
          },
          {
            path: "reset-password",
            element: <ResetPassword />
          }
        ]
      },
      {
        path: "/policies",
        element: <PolicyLayout />,
        children: [
          {
            path: "terms",
            element: <Terms />
          },
          {
            path: "privacy-policy",
            element: <PrivacyPolicy />
          }
        ]
      }
    ]
  },
  {
    path: "*",
    element: <Page404 />
  }
];

export default routes;
