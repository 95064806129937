import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input, Button } from "components";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const email = searchParams.get("email");

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm();

  useEffect(() => {
    if (!code || !email) {
      navigate("/auth/login");
    }
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    setMessage("");
    await api.auth
      .resetPassword({
        email,
        code,
        password: submitData.password
      })
      .then(() => {
        navigate("/auth/login?reset=true");
      })
      .catch(() => {
        setMessage(
          "Sıfırlama kodu yanlış veya süresi dolmuş. Lütfen tekrar şifre sıfırlama talebinde bulunun."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={"mx-auto mt-10 max-w-96"}>
      <h1 className="mb-8 text-center font-display text-3xl font-bold text-gray-800 dark:text-white">
        Yeni şifre oluştur
      </h1>
      <form className="w-full font-sans" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t("_auth.password")}
          name="password"
          error={errors.password}
          rules={{
            required: true,
            minLength: 8
          }}
          type="password"
          className="mb-4"
          register={register}
          placeholder={t("_auth.password")}
        />
        <Input
          label={t("_auth.passwordRepeat")}
          name="passwordRepeat"
          error={errors.passwordRepeat}
          rules={{
            required: true,
            minLength: 8,
            validate: val => {
              if (watch("password") !== val) {
                return false;
              }
            }
          }}
          type="password"
          className="mb-8"
          register={register}
          placeholder={t("_auth.passwordRepeat")}
        />
        <Button type="submit" isLoading={isLoading} className="font-display">
          Yeni Şifre Oluştur
        </Button>
      </form>
      {message && (
        <div className="mt-4 text-center text-sm font-medium text-red-700 font-sans">
          {message}
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
