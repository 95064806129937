import React from "react";
import { FormErrorMessage } from "components";
import Datepicker from "burakgur-react-tailwindcss-datepicker";
import { Controller } from "react-hook-form";

const DateInput = ({
  label,
  name,
  placeholder,
  control,
  mask,
  rules,
  ...props
}) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="mb-5 w-full">
          {label && (
            <label
              htmlFor={name}
              className={`mb-2 block w-full text-sm font-medium ${
                error ? "text-red-600" : "text-gray-900 dark:text-gray-50"
              }`}
            >
              {label}
            </label>
          )}
          <Datepicker
            inputId={name}
            readOnly={true}
            i18n="tr"
            asSingle={true}
            useRange={false}
            displayFormat="DD/MM/YYYY"
            placeholder={placeholder}
            primaryColor="blue"
            value={value}
            onChange={onChange}
            inputClassName={`h-[46px] w-full rounded-3xl border bg-white px-5 text-sm text-gray-700 focus:border-primary-500 focus:ring-1 focus:ring-primary-500 dark:bg-gray-900 dark:text-white ${
              error
                ? "border-red-500 focus:border-red-500"
                : "border-gray-300 focus:border-gray-700 dark:border-gray-700"
            }`}
            {...props}
          />
          <FormErrorMessage label={label} error={error} />
        </div>
      )}
    />
  );
};

export default DateInput;
