import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import { Button } from "../index";
import { isDarkTheme } from "../../utils/theme";
import { IconX } from "@tabler/icons-react";

Modal.setAppElement("#root");

const ConfirmModal = ({
  isOpen,
  closeModal,
  onClick,
  buttonTitle,
  variant = "primary"
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      minWidth: 600,
      backgroundColor: isDarkTheme() ? "#0F1113" : "#FFFFFF",
      padding: 32,
      borderRadius: 12
    },
    overlay: {
      zIndex: 100,
      backgroundColor: isDarkTheme()
        ? "rgba(0, 0, 0, 0.8)"
        : "rgba(0, 0, 0, 0.3)"
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <button
        onClick={closeModal}
        className="absolute right-2 top-2 rounded-full bg-gray-100 p-2 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:text-white hover:dark:bg-gray-900"
      >
        <IconX size={24} stroke={2} />
      </button>
      <div className="text-lg font-medium text-gray-800 dark:text-gray-100">
        İşleme devam etmek istediğinden emin misin?
      </div>
      <div className="mt-4 flex items-center gap-2">
        <Button isFullWidth={false} variant="secondary" onClick={closeModal}>
          Vazgeç
        </Button>
        <Button isFullWidth={false} variant={variant} onClick={onClick}>
          {buttonTitle}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
