import React, { useEffect, useState } from "react";
import menu from "constants/menu";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "context/auth.context";
import { IconLogout, IconPencil, IconUser } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Sun from "../../icons/Sun";
import Moon from "../../icons/Moon";
import { isDarkTheme } from "../../utils/theme";
import { Button, ChatModal, ProfileImage } from "../index";
import api from "../../services/api";
import { useScreenDetector } from "../../hooks/use-screen-detector";
import { Toaster } from "react-hot-toast";

const Sidebar = ({ activePage, isSidebarSmall, setIsSidebarOpen }) => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [theme, setTheme] = useState(isDarkTheme() ? "dark" : "light");
  const [chatModal, setChatModal] = useState(false);

  const signOut = () => {
    setUser({});
    localStorage.removeItem("user");
    api.auth
      .logout()
      .then()
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    }
  }, [theme]);

  const ThemeIcon = theme === "dark" ? Sun : Moon;

  return (
    <>
      <div
        className={`flex w-full flex-col justify-between ${isSidebarSmall ? "items-center" : ""}`}
      >
        <img
          src={
            isSidebarSmall ? "/jupy_logo-icon.svg" : "/jupy_logo-horizontal.svg"
          }
          className="mb-3 mt-1"
          width={isSidebarSmall ? 40 : 144}
        />
        <div
          className={`flex w-full flex-1 flex-col ${isSidebarSmall ? "items-center" : ""}`}
        >
          {menu.map((item, index) =>
            item.children && item.isVisible ? (
              <div key={index} className={"mt-3 mb-3 flex flex-col gap-2"}>
                {item.children.map(
                  (child, index) =>
                    child.isVisible && (
                      <button
                        onClick={() => {
                          setIsSidebarOpen(false);
                          child.name === "Soru sor"
                            ? navigate("/")
                            : navigate(child.path);
                        }}
                        key={index}
                        title={child.name}
                        className={`flex cursor-pointer items-center gap-3 rounded-full font-display font-medium text-gray-600 transition-all hover:bg-white hover:text-primary-500 dark:text-gray-300 hover:dark:text-primary-500 ${activePage.mainPath === child.mainPath
                          ? "bg-white text-primary-500 dark:text-primary-500"
                          : ""
                          } ${isSidebarSmall ? "h-10 w-10 justify-center" : "p-2 px-4"}`}
                      >
                        <child.Icon
                          size={20}
                          stroke={1.25}
                          color="currentColor"
                        ></child.Icon>
                        {!isSidebarSmall && (
                          <span className="font-display font-medium">{child.name}</span>
                        )}
                      </button>
                    )
                )}
              </div>
            ) : (
              <div key={index}></div>
            )
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div
            onClick={() =>
              setTheme(prevState => (prevState === "dark" ? "light" : "dark"))
            }
            className={`flex cursor-pointer items-center gap-4 rounded-full text-sm text-gray-600 transition-all hover:bg-white hover:text-primary-700 dark:text-gray-300 hover:dark:text-yellow-500 ${isSidebarSmall ? "ml-1 h-8 w-8 justify-center" : "px-3 py-1.5"}`}
          >
            <ThemeIcon width="18px" height="18px" />
            {!isSidebarSmall && (
              <span className={"font-display font-light text-sm"}>
                {t(`_sidebar.${theme === "dark" ? "light" : "dark"}Theme`)}
              </span>
            )}
          </div>
          <div
            className={`flex cursor-pointer items-center gap-4 rounded-full text-sm text-gray-600 transition-all hover:bg-white hover:text-red-500 dark:text-gray-300 hover:dark:text-red-500 ${isSidebarSmall ? "ml-1.5 h-8 w-8 justify-center" : "px-3 py-1.5"}`}
            onClick={signOut}
          >
            <IconLogout size={18} stroke={1.25} color="currentColor" />
            {!isSidebarSmall && (
              <span className={"font-light font-display text-sm"}>Çıkış</span>
            )}
          </div>
          <div className="my-3 -mx-4 border-t border-gray-200 dark:border-gray-700"></div>
          <div
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/settings/profile");
            }}
            className={`flex cursor-pointer items-center gap-3 rounded-full font-display text-gray-600 transition-all hover:bg-white hover:text-primary-500 dark:text-gray-300 hover:dark:text-primary-500 ${isSidebarSmall ? "ml-1.5 h-8 w-8 justify-center" : "px-3 py-1.5"}`}
          >
            <ProfileImage size={28} />
            {!isSidebarSmall && (
              <span className={"font-medium"}>
                {user.given_name} {user.family_name}
              </span>
            )}
          </div>
          <div className="hidden"> {/* "flex flex-col items-center justify-center pt-2 text-xs text-gray-400 dark:text-gray-600" */}
            {!isSidebarSmall && (
              <>
                <div
                  onClick={() => {
                    setIsSidebarOpen(false);
                    navigate("policies/terms");
                  }}
                  className="cursor-pointer font-sans transition-colors hover:text-gray-600 hover:underline dark:hover:text-gray-400"
                >
                  Kullanım Şartları ve Sözleşmeler
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ChatModal closeModal={() => setChatModal(false)} isOpen={chatModal} />
      <Toaster
        toastOptions={{
          duration: 3000,
          style: {
            background: theme === "dark" ? "#0F1113" : "#FFFFFF",
            color: theme === "dark" ? "#FFFFFF" : "#0F1113"
          }
        }}
      />
    </>
  );
};

export default Sidebar;
