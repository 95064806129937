import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const AuthLayout = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen items-center justify-center bg-white md:h-auto dark:bg-gray-950">
      <div className="w-3/5 px-4 pb-16 pt-16 md:w-full md:pb-8 md:pt-8">
        <img
          src="/jupy_logo-vertical.svg"
          className={"mx-auto my-8 cursor-pointer"}
          width={164}
          height={164}
          alt="Lexgine"
          onClick={() => navigate("/auth/welcome")}
        />
        <Outlet />
        <div className="mt-20 flex items-center justify-center text-sm md:mt-12">
          <div
            className="font-sans cursor-pointer text-gray-600 transition-all hover:text-gray-900 hover:underline dark:hover:text-gray-300"
            onClick={() => navigate("/policies/terms")}
          >
            Kullanım Şartları ve Sözleşmeler
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
