export const searchApi = api => ({
  searchMevzuat(params) {
    const formattedParams = {
      ...params,
      mevzuat_tur: Array.isArray(params.mevzuat_tur) 
        ? params.mevzuat_tur.map(item => {
            if (typeof item === 'string') return item;
            return item.value;
          })
        : [],
      kabul_tarih_start: params.kabul_tarih_start ? new Date(params.kabul_tarih_start).toISOString().split('T')[0] : undefined,
      kabul_tarih_end: params.kabul_tarih_end ? new Date(params.kabul_tarih_end).toISOString().split('T')[0] : undefined,
      rg_tarih_start: params.rg_tarih_start ? new Date(params.rg_tarih_start).toISOString().split('T')[0] : undefined,
      rg_tarih_end: params.rg_tarih_end ? new Date(params.rg_tarih_end).toISOString().split('T')[0] : undefined
    };
    return api.post("/search/mevzuat", formattedParams);
  },
  searchIctihat(params) {
    const formattedParams = {
      ...params,
      index: Array.isArray(params.index)
        ? params.index.map(item =>
            typeof item === 'string' ? item : item.value
          )
        : [],
      daire: Array.isArray(params.daire)
        ? params.daire.map(item => item)
        : [],
      karar_tarih_start: params.karar_tarih_start
        ? new Date(params.karar_tarih_start).toISOString().split('T')[0]
        : undefined,
      karar_tarih_end: params.karar_tarih_end
        ? new Date(params.karar_tarih_end).toISOString().split('T')[0]
        : undefined
    };
    return api.post("/search/ictihat", formattedParams);
  }
});
