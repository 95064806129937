import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { streamFetch } from "../../utils/service";
import { API_URL } from "../../config";
import AbortController from "abort-controller";
import { useAuth } from "../../context/auth.context";
import { scrollTo } from "utils/helper";

const AllDocs = () => {
  const { threadId, responseId } = useParams();
  const navigate = useNavigate();
  const [sources, setSources] = useState({
    mevzuat: [],
    ictihat: {
      kurul_kararlari: [],
      yargitay_kararlari: [],
      danistay_kararlari: [],
      bolge_adliye_kararlari: []
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const controller = new AbortController();
  const [status, setStatus] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    const init = async () => {
      await streamFetch(
        {
          url: `${API_URL}/chat/sources?thread_id=${threadId}&response_id=${responseId}`,
          token: user.token,
          signal: controller.signal
        },
        async chunk => {
          try {
            if (
              !chunk ||
              !chunk.includes("data: ") ||
              chunk === "data: [DONE]" ||
              chunk === 'data: {"event": "DONE"}'
            ) {
              return;
            }
            setIsLoading(false);
            const chunkData = JSON.parse(chunk.split("data: ")[1]);

            if (chunkData.error) {
              setError({
                message: "Analiz sırasında bir hata oluştu. Lütfen tekrar deneyin.",
                details: chunkData.error
              });
              controller.abort();
              return;
            }

            if (chunkData.status) {
              setStatus(chunkData.status);
            }

            if (chunkData.mevzuat) {
              setSources(prev => ({
                ...prev,
                mevzuat: [...prev.mevzuat, chunkData.mevzuat]
              }));
            }

            if (chunkData.ictihat) {
              const classificationMap = {
                "yargitay-kurul-kararlari": "kurul_kararlari",
                "yargitay-daire-kararlari": "yargitay_kararlari",
                "danistay-daire-kararlari": "danistay_kararlari",
                "bolge-adliye-kararlari": "bolge_adliye_kararlari"
              };

              const arrayKey =
                classificationMap[chunkData.ictihat.classification];

              if (arrayKey) {
                setSources(prev => {
                  const currentIndex = prev.ictihat[arrayKey].findIndex(
                    item => item.document_id === chunkData.ictihat.document_id
                  );
                  let newValue;
                  if (currentIndex > -1) {
                    newValue = [...prev.ictihat[arrayKey]];
                    newValue[currentIndex] = {
                      ...newValue[currentIndex],
                      ...chunkData.ictihat
                    };
                  } else {
                    newValue = [...prev.ictihat[arrayKey], chunkData.ictihat];
                  }
                  return {
                    ...prev,
                    ictihat: {
                      ...prev.ictihat,
                      [arrayKey]: newValue
                    }
                  };
                });
              }
            }
            if (
              chunkData.event === "DONE" ||
              chunkData.status === "DONE" ||
              chunkData.status === "İşlem tamamlandı"
            ) {
              setStatus("");
            }
          } catch (parseError) {
            console.error("Error parsing chunk:", parseError);
            setError({
              message: "Veri işlenirken bir hata oluştu.",
              details: parseError.message
            });
          }
        }
      );
    };

    init();

    return () => {
      controller.abort();
    };
  }, []);

  const MevzuatContent = ({ item }) => {
    return (
      <div>
        <div className="font-title text-lg font-semibold text-gray-800 dark:text-gray-200 sm:list-none list-item ml-4 sm:ml-0 group-hover:text-primary-400 dark:group-hover:text-primary-400 transition-colors">
          {item.title}
        </div>
        <div className="mt-2 flex gap-3 text-xs font-sans text-gray-600 flex-col gap-1 dark:text-gray-300 ml-4 sm:ml-0">
          <div className={"flex gap-1"}>
            <span>Tür:</span>
            <strong className="font-medium">{item.mevzuat_tur}</strong>
          </div>
          <div className="flex gap-1">
            <span>Mevzuat Numarası:</span>
            <strong className="font-medium">{item.mevzuat_no}</strong>
          </div>
          {item.kabul_tarihi && (
            <div className="flex gap-1">
              <span>Kabul Tarihi:</span>
              <strong className="font-medium">{item.kabul_tarihi}</strong>
            </div>
          )}
          <div className="flex gap-1">
            <span>Resmi Gazete Sayısı:</span>
            <strong className="font-medium">{item.gazete_sayisi}</strong>
          </div>
          <div className="flex gap-1">
            <span>Resmi Gazete Tarihi:</span>
            <strong className="font-medium">{item.gazete_tarihi}</strong>
          </div>
        </div>
      </div>
    );
  };

  const IctihatContent = ({ item, type }) => {
    return (
      <div>
        {item.title && item.summary ? (
          <>
            <div className="font-title text-lg font-semibold text-black dark:text-white sm:list-none list-item ml-4 sm:ml-0 group-hover:text-primary-400 dark:group-hover:text-primary-400 transition-colors">
              {item.title} {type === "bolge_adliye_kararlari" ? `[${item.durum}]` : ""}
            </div>
            <div className="my-2 text-sm font-sans text-gray-500 dark:text-gray-200 ml-4 sm:ml-0">
              {item.summary}
            </div>
          </>
        ) : (
          <div className="space-y-2">
            <div className="h-4 w-3/4 animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>
            <div className="h-10 w-full animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>
          </div>
        )}
        <div className="mt-2 mb-4 flex gap-3 text-xs font-sans text-gray-600 flex-col gap-1 dark:text-gray-300 ml-4 sm:ml-0">
          <div className={"flex gap-1"}>
            <span>{type === "kurul" ? "Kurul:" : "Daire:"}</span>
            <strong className="font-medium">{item.daire}</strong>
          </div>
          <div className="flex gap-1">
            <span>Esas No:</span>
            <strong className="font-medium">{item.esas_no}</strong>
          </div>
          <div className="flex gap-1">
            <span>Karar No:</span>
            <strong className="font-medium">{item.karar_no}</strong>
          </div>
          <div className="flex gap-1">
            <span>Karar Tarihi:</span>
            <strong className="font-medium">{item.karar_tarihi}</strong>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <div className="mx-auto w-full max-w-4xl">
        {status && (
          <div className="mb-8 text-left">
            <span className="inline-block text-base font-medium bg-gradient-to-r from-primary-500 via-accent-500 to-primary-500 animate-typing bg-[length:300%_100%] animated-text">
              {status}
            </span>
          </div>
        )}
        {error && (
          <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
            <div className="mb-4 rounded-md bg-red-50 p-4 dark:bg-red-900/50">
              <div className="text-center text-sm font-medium text-red-800 dark:text-red-200">
                <div className="my-2 text-lg font-medium text-red-700 dark:text-red-300">
                  {error.message}
                  {error.details && (
                    <div className="mt-2 text-sm text-red-600 dark:text-red-400">
                      {error.details}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <section className={"mb-10 flex min-w-full flex-col"}>
            {sources.ictihat && (
              <>
                <h3 className="mb-4 text-lg font-display font-semibold text-primary-500">
                  İçtihat
                </h3>
                {sources.ictihat?.kurul_kararlari?.length > 0 && (
                  <>
                    <h4 className="ml-4 list-item text-base font-bold text-gray-700 dark:text-gray-100">
                      Kurul Kararları
                    </h4>
                    <div className="ml-4">
                      {sources.ictihat?.kurul_kararlari?.map((item, i) => (
                        <div
                          onClick={() =>
                            navigate(
                              `/doc/ictihat?s3Url=${item.s3_url_html || item.s3_url_json}`
                            )
                          }
                          className={`cursor-pointer py-3 ${i === 0 ? "" : "border-t border-gray-100 dark:border-gray-800"
                            }`}
                          key={`kurul-${i}`}
                        >
                          <IctihatContent item={item} type="kurul" />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {sources.ictihat?.yargitay_kararlari?.length > 0 && (
                  <>
                    <h4 className="ml-4 sm:ml-0 mb-2 text-lg font-display font-semibold text-primary-500">
                      Yargıtay Kararları
                    </h4>
                    <div className="ml-4 sm:ml-0 mb-2">
                      {sources.ictihat?.yargitay_kararlari?.map((item, i) => (
                        <Link
                          to={`/doc/ictihat?s3Url=${item.s3_url_html || item.s3_url_json}`}
                          className="group cursor-pointer py-3 hover:bg-gray-50 dark:hover:bg-gray-800/50 rounded-lg transition-colors"
                          key={`yargitay-${i}`}
                        >
                          <IctihatContent item={item} type="yargitay" />
                        </Link>
                      ))}
                    </div>
                  </>
                )}
                {sources.ictihat?.danistay_kararlari?.length > 0 && (
                  <>
                    <h4 className="ml-4 sm:ml-0 mb-2 text-lg font-display font-semibold text-primary-500">
                      Danıştay Kararları
                    </h4>
                    <div className="ml-4 sm:ml-0">
                      {sources.ictihat?.danistay_kararlari?.map((item, i) => (
                        <Link
                          to={`/doc/ictihat?s3Url=${item.s3_url_html || item.s3_url_json}`}
                          className="group cursor-pointer py-3 hover:bg-gray-50 dark:hover:bg-gray-800/50 rounded-lg transition-colors"
                          key={`danistay-${i}`}
                        >
                          <IctihatContent item={item} type="danistay" />
                        </Link>
                      ))}
                    </div>
                  </>
                )}
                {sources.ictihat?.bolge_adliye_kararlari?.length > 0 && (
                  <>
                    <h4 className="ml-4 sm:ml-0 mb-2 text-lg font-display font-semibold text-primary-500">
                      (Bölge Adliye) Hukuk Mahkemesi Kararları
                    </h4>
                    <div className="ml-4 sm:ml-0">
                      {sources.ictihat?.bolge_adliye_kararlari?.map((item, i) => (
                        <Link
                          to={`/doc/ictihat?s3Url=${item.s3_url_html || item.s3_url_json}`}
                          className="group cursor-pointer py-3 hover:bg-gray-50 dark:hover:bg-gray-800/50 rounded-lg transition-colors"
                          key={`bolge_adliye_kararlari-${i}`}
                        >
                          <IctihatContent
                            item={item}
                            type="bolge_adliye_kararlari"
                          />
                        </Link>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
            {sources.mevzuat && sources.mevzuat.length > 0 && sources.ictihat && (
              <div className="my-6 border-t border-gray-200 dark:border-gray-700"></div>
            )}
            {sources.mevzuat && (
              <>
                <h3 className="mt-2 text-lg font-display font-semibold text-primary-500">
                  Mevzuat
                </h3>
                {sources.mevzuat?.map((item, i) => (
                  <Link
                    to={`/doc/mevzuat?s3Url=${item.s3_url_html || item.s3_url_json}`}
                    className={`cursor-pointer py-3 ${i === 0 ? "" : "border-t border-gray-100 dark:border-gray-800"}`}
                    key={`mevzuat-${i}`}
                    onClick={() => scrollTo(0, 'instant')}
                  >
                    <MevzuatContent item={item} />
                  </Link>
                ))}
              </>
            )}
          </section>
        )}
      </div>
    </div>
  );
};

export default AllDocs;
