import React from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center text-center md:px-5">
      <h1 className="mt-10 font-display text-4xl font-medium text-gray-950 dark:text-white">
        {t("_auth.welcomeTitle")}
      </h1>
      <p className="mt-3 px-4 font-sans text-lg text-gray-700 md:px-0 dark:text-gray-300">
        {t("_auth.welcomeDescription")}
      </p>
      <div className="mt-8 flex gap-3">
        <Button className="min-w-32 font-display" onClick={() => navigate("/auth/login")}>
          Giriş Yap
        </Button>
        <Button
          className="min-w-32 font-display hover:bg-primary-600 hover:text-white"
          onClick={() => navigate("/auth/register")}
          variant="secondary"
        >
          Üye Ol
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
