/**
 * @param {number} top
 * @param {'auto' | 'smooth' | 'instant'} [behavior='smooth']
 */

export const scrollTo = (top, behavior = 'smooth') => {
    window.scrollTo({
        top,
        behavior,
    });
};