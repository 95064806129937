import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../../services/api";

const Confirm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    const confirmEmail = async () => {
      const code = searchParams.get("code");
      const email = searchParams.get("email");

      if (code && email) {
        try {
          await api.post("/auth/confirm", { code, email });
          setIsConfirmed(true);
        } catch (error) {
          console.error("Confirmation failed:", error);
        }
      }
      setIsLoading(false);
    };

    confirmEmail();
  }, [searchParams]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
        <p className="mt-4 text-gray-600 font-sans dark:text-gray-300">Confirming your registration...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      {isConfirmed ? (
        <>
          <h2 className="text-2xl font-display text-gray-800 mb-4 dark:text-white">Your registration has been confirmed!</h2>
          <p className="text-gray-600 mb-6 font-sans dark:text-gray-300">Thank you for verifying your email address.</p>
          <button
            className="bg-primary-600 hover:bg-primary-700 text-white font-display py-2 px-4 rounded"
            onClick={() => navigate("/auth/login")}
          >
            Go to Login
          </button>
        </>
      ) : (
        <>
          <h2 className="text-2xl font-display text-gray-800 mb-4 dark:text-white">Confirmation Failed</h2>
          <p className="text-red-600 mb-6 font-sans">Invalid confirmation link. Please try again or contact support.</p>
          <button
            className="bg-primary-600 hover:bg-primary-700 text-white font-display py-2 px-4 rounded"
            onClick={() => navigate("/auth/register")}
          >
            Back to Registration
          </button>
        </>
      )}
    </div>
  );
};

export default Confirm;
