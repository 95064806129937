import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth.context";
import { Button, Input, MaskInput, ProfileImage } from "../../components";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import toast from "react-hot-toast";
import { sendEvent } from "../../utils/event";
import PROFILE_LIST from "../../constants/profile-list";
import { useScreenDetector } from "hooks/use-screen-detector";
import { IconCheck, IconPencil, IconX } from "@tabler/icons-react";


const Profile = () => {
  const { user, setUser } = useAuth();

  const {
    register: given_name_register,
    formState: { errors: given_name_errors },
    handleSubmit: handleSubmitGivenName
  } = useForm({
    defaultValues: {
      given_name: user.given_name,
    }
  });

  const {
    register: family_name_register,
    formState: { errors: family_name_errors },
    handleSubmit: handleSubmitFamilyName
  } = useForm({
    defaultValues: {
      family_name: user.family_name
    }
  });

  const {
    register: bar_id_register,
    formState: { errors: bar_id_errors },
    handleSubmit: handleSubmitBarId
  } = useForm({
    defaultValues: {
      bar_id: user.bar_id
    }
  });

  const {
    register: bar_name_register,
    formState: { errors: bar_name_errors },
    handleSubmit: handleSubmitBarName
  } = useForm({
    defaultValues: {
      bar_name: user.bar_name
    }
  });

  const {
    register: phone_number_register,
    formState: { errors: phone_number_errors },
    handleSubmit: handleSubmitPhoneNumber
  } = useForm({
    defaultValues: {
      phone_number: user.phone_number || ""
    }
  });

  const [profileList, setProfileList] = useState([
    {
      name: "Ad",
      isEditable: true,
      showInput: false,
      value: "given_name",
      isRequired: true,
      register: given_name_register,
      errors: given_name_errors,
      submit: (onSubmit) => handleSubmitGivenName(onSubmit)
    },
    {
      name: "Soyad",
      isEditable: true,
      showInput: false,
      value: "family_name",
      isRequired: true,
      register: family_name_register,
      errors: family_name_errors,
      submit: (onSubmit) => handleSubmitFamilyName(onSubmit)
    },
    {
      name: "E-Posta",
      isEditable: false,
      showInput: false,
      value: "email",
      isRequired: false,
      register: null,
      errors: null,
      submit: () => { }
    },
    {
      name: "Kayıtlı Büro",
      isEditable: true,
      showInput: false,
      value: "bar_name",
      isRequired: false,
      register: bar_name_register,
      errors: bar_name_errors,
      submit: (onSubmit) => handleSubmitBarName(onSubmit)
    },
    {
      name: "Baro Sicil No",
      isEditable: true,
      showInput: false,
      value: "bar_id",
      isRequired: false,
      register: bar_id_register,
      errors: bar_id_errors,
      submit: (onSubmit) => handleSubmitBarId(onSubmit)
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [file, setFile] = useState(null);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const { isMobile } = useScreenDetector();

  useEffect(() => {
    sendEvent("view_profile");
  }, []);

  const onSubmit = async submitData => {
    console.log('submitData', submitData);
    setIsLoading(true);
    await api.user
      .updateProfile(submitData)
      .then(res => {
        setUser(prev => ({
          ...prev,
          ...res.data
        }));
        toast.success("Değişiklik başarıyla kaydedildi.");
        setProfileList(prev =>
          prev.map(item => submitData[item.value] ? { ...item, showInput: false } : item)
        );
      })
      .catch(() => {
        toast.error("Bir hata oluştu.");
      });
    setIsLoading(false);
  };

  const uploadNewProfileImage = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      await api.user
        .uploadProfileImage(formData)
        .then(res => {
          setUser(prev => ({
            ...prev,
            profile_image: res.data.profile_image_url
          }));
          toast.success("Profil resmi başarıyla değişti.");
        })
        .catch(() => {
          toast.error("Bir hata oluştu.");
        });
    }
  };

  const deleteProfileImage = () => {
    api.user
      .deleteProfileImage()
      .then(() => {
        setUser(prev => ({
          ...prev,
          profile_image: ""
        }));
        toast.success("Profil resmi başarıyla kaldırıldı.");
      })
      .catch(() => {
        toast.error("Bir hata oluştu.");
      });
  };

  return (
    <div className="flex w-full flex-col justify-center">
      <div className="flex border-b border-gray-100 py-6 dark:border-gray-800 md:flex-col">
        <div className="w-1/4 pt-1 text-base font-semibold text-gray-800 dark:text-gray-100 md:mb-2 md:w-full">
          Profil Resmi
        </div>
        <div className="flex flex-1 justify-between md:items-center">
          <ProfileImage size={96} />
          <div className="flex justify-end gap-2 pt-1">
            {!showUploadImage && (
              <>
                <Button
                  isFullWidth={false}
                  variant="primary"
                  onClick={() => setShowUploadImage(true)}
                >
                  Yeni Resim Yükle
                </Button>
                <Button
                  isFullWidth={false}
                  variant="danger"
                  onClick={deleteProfileImage}
                >
                  Sil
                </Button>
              </>
            )}
            {showUploadImage && (
              <div className="flex flex-col space-y-2">
                <input
                  className="text-sm text-gray-600"
                  type="file"
                  accept="image/*"
                  onChange={e => {
                    setFile(e.target.files[0]);
                  }}
                />
                <span className="flex text-xs text-gray-400">
                  JPG veya PNG. Maksimum 1 MB dosya boyutu olmalıdır.
                </span>
                <Button onClick={uploadNewProfileImage}>Yükle</Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full" >
        {profileList.map((item, index) => (
          <form
            onSubmit={item.submit(onSubmit)}
            key={index}
            className="flex w-full justify-between border-b border-gray-100 py-6 dark:border-gray-800 md:flex-col"
          >
            <div className="w-1/4 text-base font-semibold text-gray-800 dark:text-gray-100 md:mb-2 md:w-full">{item.name}</div>
            <div className="flex flex-1 justify-between md:items-center">
              {item.showInput ? (
                <Input
                  label=""
                  name={item.value}
                  error={item.errors[item.value]}
                  rules={{ required: item.isRequired }}
                  register={item.register}
                  className="max-w-[400px]"
                  placeholder={item.name}
                />
              ) : (
                <span className="text-base text-gray-900 dark:text-white">{user[item.value] || "Belirtilmemiş"}</span>
              )}
              <div className="flex-end flex gap-2 ml-2">
                {item.showInput && (
                  <Button
                    isFullWidth={false}
                    variant="primary"
                    type="submit"
                    isIcon={true}
                  >
                    <IconCheck size={20} />
                  </Button>
                )}
                {item.isEditable && (
                  <Button
                    isFullWidth={false}
                    variant="secondary"
                    type="button"
                    isIcon={true}
                    onClick={() => {
                      setProfileList(prev =>
                        prev.map((prevItem, prevIndex) => {
                          if (prevIndex === index) {
                            return {
                              ...prevItem,
                              showInput: !prevItem.showInput
                            };
                          }
                          return prevItem;
                        })
                      );
                    }}
                  >
                    {item.showInput ? <IconX size={20} /> : <IconPencil size={20} />}
                  </Button>
                )}
              </div>
            </div>
          </form>
        ))}
        <form
          className="flex w-full justify-between border-b border-gray-100 py-6 dark:border-gray-800 md:flex-col"
          onSubmit={handleSubmitPhoneNumber(onSubmit)}
        >
          <div className="w-1/4 text-base font-semibold text-gray-800 dark:text-gray-100 md:mb-2 md:w-full">Telefon Numarası</div>
          <div className="flex flex-1 justify-between md:items-center">
            {showPhoneInput ? (
              <MaskInput
                label=""
                mask="0599 999 99 99"
                maskPlaceholder="0555 555 555 55"
                name="phone_number"
                // @ts-ignore
                error={phone_number_errors.phone_number}
                register={phone_number_register}
                rules={{}}
                className="max-w-[400px]"
                placeholder="Telefon Numarası"
              />
            ) : (
              <span className="text-base text-gray-900 dark:text-white">{user.phone_number || "Belirtilmemiş"}</span>
            )}
            <div className="flex-end flex gap-2 ml-2">
              {showPhoneInput && (
                <Button
                  isFullWidth={false}
                  variant="primary"
                  type="submit"
                  isIcon={true}
                >
                  <IconCheck size={20} />
                </Button>
              )}
              <Button
                isFullWidth={false}
                variant="secondary"
                type="button"
                isIcon={true}
                onClick={() => setShowPhoneInput(prev => !prev)}
              >
                {showPhoneInput ? <IconX size={20} /> : <IconPencil size={20} />}
              </Button>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};

export default Profile;
