import React, { useState, useEffect } from "react";
import { ChatInput } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useScreenDetector } from "../../hooks/use-screen-detector";
import api from "../../services/api";
import { sendEvent } from "../../utils/event";

const DEFAULT_THREADS = [
  {
    title:
      "Hâkim şirketin, bağlı şirket alacaklılarına karşı sorumluluğu ve şartları nelerdir ve bu konuda içtihatlar nasıl bir yönelim göstermektedir?"
  },
  {
    title:
      "Örtülü kar dağıtımı yasağının, transfer fiyatlandırması ile ilişkisi nedir ve bu konuda vergi hukuku ile şirketler hukuku arasındaki etkileşim nasıl yorumlanır?"
  },
  {
    title:
      "Evliliğin bir yılı dolmadan, terk sebebiyle (TMK 164) boşanma davası açılabilir mi?"
  },
  {
    title:
      "Türk hukukunda 'culpa in contrahendo' ilkesinin uygulanması nasıldır ve hangi Yargıtay kararlarında yer alır?"
  }
];

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDefaultThread, setSelectedDefaultThread] = useState(null);
  const { isMobile } = useScreenDetector();
  const [threads, setThreads] = useState([]);

  const onSuccess = data => {
    navigate("/chat/new", { state: { data } });
    sendEvent("submit_new_query", { query_text: data });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const res = await api.prompting.randomQueries();

        const sampleQueries = res?.data?.sample_queries
          ? Object.values(res.data.sample_queries).map(query => ({
            title: query
          }))
          : [];

        setThreads(sampleQueries);
      } catch (err) {
        console.error("Error fetching sample queries:", err);
      }
    };
    init();
    sendEvent("home_page_view");
  }, []);

  const renderThreadItem = (item, index) => (
    <div key={`default-thread-${index}`} className="mb-2 md:mb-0 md:h-full w-full">
      <div
        onClick={() => {
          setSelectedDefaultThread(item.title);
          sendEvent("click_sample_query", { query_text: item.title });
        }}
        className="font-small cursor-pointer rounded-2xl border border-gray-200 px-5 py-2 text-sm text-gray-800 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 hover:dark:bg-gray-800 md:h-full flex items-center justify-center w-full h-full"
      >
        {item.title}
      </div>
    </div>
  );

  return (
    <div className="flex h-full w-full flex-col items-center justify-center md:h-auto md:min-h-full">
      <img
        src={"/jupy_logo-icon.svg"}
        width={48}
        height={48}
        className="mb-8 md:mb-4"
      />
      <h1 className="mb-8 md:mb-2 font-display text-2xl font-medium text-gray-900 dark:text-white text-center">
        Artık jupy.tr adresinden hizmet veriyoruz
      </h1>
      <p className="text-black-600 mb-4 md:mb-2 mt-4 md:mt-2 font-display text-center lg:text-left text-base dark:text-white">
        Gelişmeler:
      </p>
      <div className="w-full max-w-2xl px-4">
        <p className="mb-2 text-center lg:text-left text-sm text-gray-600 dark:text-gray-200">
          <span className="inline lg:inline-block lg:w-6">🔍</span>
          İçtihat ve mevzuat arama iyileştirildi. Direkt chat ekranından da arama
          yapabilirsiniz.
        </p>
        <p className="mb-2 text-center lg:text-left text-sm text-gray-600 dark:text-gray-200">
          <span className="inline lg:inline-block lg:w-6">🗓️</span>
          Güncel karar analizi eklendi. Karar detay sayfasından
          erişebilirsiniz.
        </p>
        {/* <p className="mb-6 text-center lg:text-left text-sm text-gray-600 dark:text-gray-200">
          <span className="inline lg:inline-block lg:w-6">🧠</span>
          Soru cevap iyileştirildi. Arka arkaya sorulan sorularda daha iyi
          sonuçlar alabilirsiniz.
        </p> */}
      </div>
      <p className="mt-4 mb-4 md:mt-2 md:mb-2 text-black-600 font-display text-center text-base dark:text-white">
        Örnek sorular:
      </p>
      <div className="mb-4 md:mb-4 w-full max-w-2xl">
        {isMobile ? (
          <div className="flex flex-col gap-2">
            {Object.values(threads).slice(0, 2).map(renderThreadItem)}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2 md:grid-rows-2 h-full">
            {Object.values(threads).map(renderThreadItem)}
          </div>
        )}
      </div>
      <ChatInput onSuccess={onSuccess} newThread={selectedDefaultThread} />
    </div>
  );
};

export default Home;
