import React from "react";
import { FormErrorMessage } from "components";
import Select from "react-select";
import { Controller } from "react-hook-form";

const AppSelect = ({
  label,
  options,
  name,
  error,
  rules,
  control,
  placeholder,
  isMulti = false
}) => {
  return (
    <div className="mb-5 w-full">
      {label && (
        <label
          htmlFor={name}
          className={`mb-2 block w-full text-sm font-medium ${
            error ? "text-red-600" : "text-gray-900 dark:text-gray-50"
          }`}
        >
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            className="rounded-full"
            classNamePrefix={"react-select"}
            isMulti={isMulti}
            isClearable={true}
            options={options}
            placeholder={placeholder}
          />
        )}
      />
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppSelect;
