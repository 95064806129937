export const userApi = api => ({
  getProfile() {
    return api.get("/auth/profile");
  },
  updateProfile(payload) {
    return api.put("/profile", payload);
  },
  deleteProfile() {
    return api.delete("/profile");
  },
  getProfileImage() {
    return api.get("/profile/image");
  },
  deleteProfileImage() {
    return api.delete("/profile/image");
  },
  uploadProfileImage(payload) {
    return api.post("/profile/image", payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  changePassword(payload) {
    return api.post("/auth/change-password", payload);
  },
});
