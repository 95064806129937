import React from "react";
import { useAuth } from "../../context/auth.context";

const ProfileImageComponent = ({ size }) => {
  const { user } = useAuth();

  return (
    <div className="rounded-full border border-gray-200 dark:border-gray-800">
      <img
        src={user.profile_image || "/default-profile.jpg"}
        width={size}
        height={size}
        alt={user.given_name}
        className="rounded-full"
      />
    </div>
  );
};

export default ProfileImageComponent;
