import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ThreadItem, ThreadChatInput } from "../../components";
import { copyFn } from "../../utils/editor";
import api from "../../services/api";
import { streamFetch } from "../../utils/service";
import { useAuth } from "../../context/auth.context";
import { API_URL } from "../../config";
import AbortController from "abort-controller";
import { sendEvent } from "../../utils/event";

const Thread = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [newThread, setNewThread] = useState("");
  const [isLoading, setIsLoading] = useState([]);
  const [status, setStatus] = useState("");
  const controller = new AbortController();
  const signal = controller.signal;

  if (!id) {
    return;
  }

  useEffect(() => {
    const init = async () => {
      setIsLoading([true]);
      const userQuery = location.state?.data?.user_query;
      if (id === "new" && userQuery && !isLoading[0]) {
        setData([{ user_query: userQuery, assistant_response: "", reference_mapping: {} }]);
        let i = 0;
        await streamFetch(
          {
            url: `${API_URL}/chat/query`,
            method: "POST",
            token: user.token,
            body: {
              query: userQuery
            },
            signal
          },
          chunk => {
            if (
              !chunk ||
              !chunk.includes("data: ") ||
              chunk === "data: [DONE]" ||
              chunk === 'data: {"event": "DONE"}'
            ) {
              return;
            }
            const chunkData = JSON.parse(chunk.split("data: ")[1]);
            if (chunkData.status) {
              setStatus(chunkData.status);
              return;
            }
            if (i === 0) {
              setStatus("");
              setIsLoading([false]);
              const threadId = chunkData.thread_id;
              window.history.pushState({}, "", `/chat/${threadId}`);
              setData(() => [
                {
                  ...chunkData,
                  user_query: userQuery,
                  assistant_response: "",
                  reference_mapping: chunkData.reference_mapping || {}
                }
              ]);
            } else if (chunkData.assistant_response) {
              setData(prevState => [
                {
                  ...prevState[0],
                  assistant_response:
                    prevState[0].assistant_response + chunkData.assistant_response
                }
              ]);
            } else {
              setData(prevState => [
                {
                  ...prevState[0],
                  ...chunkData
                }
              ]);
            }
            i++;
          }
        );
      } else {
        api.chat
          .getChatQuery({ threadId: id })
          .then(res => {
            const threadDetails = res.data.thread_details.map(item => ({
              ...item,
              reference_mapping: item.reference_mapping || {}
            }));
            setData(threadDetails.filter(item => !item.edited));
            setIsLoading([false]);
          })
          .catch(err => {
            console.log("err", err);
          });
      }
    };

    init();
  }, [id]);

  useEffect(() => {
    document.body.addEventListener("copy", copyFn);

    return () => {
      document.body.removeEventListener("copy", copyFn);
      controller.abort();
    };
  }, []);

  const onAddNewThread = async threadData => {
    setIsLoading(prevState => [...prevState, true]);
    const { user_query } = threadData;
    const index = data.length;
    setData(prevState => [
      ...prevState,
      {
        user_query: user_query,
        assistant_response: "",
        reference_mapping: {}
      }
    ]);
    setTimeout(() => {
      const getIndexElement = document.querySelector(`[data-index="${index}"]`);

      if (getIndexElement) {
        getIndexElement.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    }, 100);
    let i = 0;
    sendEvent("submit_followup_query", {
      followup_text: user_query,
      thread_id: data[0].thread_id
    });
    await streamFetch(
      {
        url: `${API_URL}/chat/query`,
        token: user.token,
        method: "POST",
        body: {
          query: user_query,
          thread_id: data[0].thread_id
        },
        signal
      },
      chunk => {
        if (
          !chunk ||
          !chunk.includes("data: ") ||
          chunk === "data: [DONE]" ||
          chunk === 'data: {"event": "DONE"}'
        ) {
          return;
        }
        const chunkData = JSON.parse(chunk.split("data: ")[1]);
        if (chunkData.status) {
          setStatus(chunkData.status);
          return;
        }
        if (i === 0) {
          setIsLoading(prevState => [...prevState.slice(0, index), false]);
          setStatus("");
          setData(prevState => [
            ...prevState.slice(0, index),
            {
              ...prevState[index],
              ...chunkData,
              assistant_response: "",
              reference_mapping: chunkData.reference_mapping || {}
            }
          ]);
        } else if (chunkData.assistant_response) {
          setData(prevState => [
            ...prevState.slice(0, index),
            {
              ...prevState[index],
              assistant_response:
                prevState[index].assistant_response + chunkData.assistant_response
            }
          ]);
        }
        i++;
      }
    );
    setNewThread("");
  };

  const onUpdateQuery = async (title, index) => {
    const threadId = data[index].thread_id;
    const responseId = data[index].response_id;
    sendEvent("edit_query", {
      edited_text: title,
      thread_id: data[0].thread_id
    });
    let i = 0;
    await streamFetch(
      {
        url: `${API_URL}/chat/edit-query`,
        token: user.token,
        method: "POST",
        body: {
          thread_id: threadId,
          new_query: title,
          response_id: responseId
        },
        signal
      },
      chunk => {
        if (
          !chunk ||
          !chunk.includes("data: ") ||
          chunk === "data: [DONE]" ||
          chunk === 'data: {"event": "DONE"}'
        ) {
          return;
        }
        const chunkData = JSON.parse(chunk.split("data: ")[1]);
        if (chunkData.status) {
          setStatus(chunkData.status);
          return;
        }
        if (i === 0) {
          setIsLoading(prevState => [...prevState.slice(0, index), false]);
          setStatus("");
          setData(prevState => [
            ...prevState.slice(0, index),
            {
              ...prevState[index],
              ...chunkData,
              assistant_response: "",
              reference_mapping: chunkData.reference_mapping || {}
            }
          ]);
        } else if (chunkData.assistant_response) {
          setData(prevState => [
            ...prevState.slice(0, index),
            {
              ...prevState[index],
              assistant_response:
                prevState[index].assistant_response + chunkData.assistant_response
            }
          ]);
        }
        i++;
      }
    );
  };

  return (
    <div className="flex w-full flex-col items-center pb-32 md:pb-24 font-sans">
      {data.map((item, index) => {
        const combinedReferenceMapping = data.reduce((acc, item) => {
          return { ...acc, ...(item.reference_mapping || {}) };
        }, {});

        return (
          <ThreadItem
            key={`thread-item-${index}`}
            className={`${index > 0 ? "mt-8 md:mt-2 border-t pt-16 md:pt-2 dark:border-gray-800" : ""
              }`}
            threadData={item}
            addNewThread={title => setNewThread(title)}
            updateQuery={title => onUpdateQuery(title, index)}
            isLoading={isLoading[index]}
            index={index}
            status={status}
            isLastMessage={index === data.length - 1}
            combinedReferenceMapping={combinedReferenceMapping}
          />
        );
      })}
      <ThreadChatInput
        newThread={newThread}
        threadId={data[0] && data[0].thread_id}
        onSuccess={onAddNewThread}
        isPrimaryBorder={true}
      />
    </div>
  );
};

export default Thread;
