const SECURITY_LIST = [
    {
        name: "Mevcut Şifre",
        isEditable: true,
        showInput: true,
        value: "previous_password",
        isRequired: true
    },
    {
        name: "Yeni Şifre",
        isEditable: true,
        showInput: false,
        value: "new_password",
        isRequired: true
    },
    {
        name: "Yeni Şifre Tekrar",
        isEditable: true,
        showInput: true,
        value: "confirm_password",
        isRequired: true
    },

];

export default SECURITY_LIST;