import React, { useState, useEffect } from "react";
import { FormErrorMessage } from "components";

const CustomNoInput = ({
  label = "",
  name,
  firstPlaceholder = "",
  secondPlaceholder = "",
  register,
  error,
  rules = {},
  className = "",
  setValue,
  ...props
}) => {
  const [firstPart, setFirstPart] = useState("");
  const [secondPart, setSecondPart] = useState("");

  useEffect(() => {
    if (setValue) {
      setValue(name, `${firstPart || "*"}/${secondPart || "*"}`);
    }
  }, [firstPart, secondPart]);

  const handleChange = (e, part) => {
    const value = e.target.value.replace(/\D/g, "");

    if (value.length <= 5) {
      if (part === "first") {
        setFirstPart(value);
        if (value.length === 5) {
          document.getElementById(`${name}-second`).focus();
        }
      } else {
        setSecondPart(value);
      }
    }
  };

  return (
    <div className={`relative w-full max-w-full overflow-scroll ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className={`mb-1.5 block w-full text-left text-sm font-medium ${
            error ? "text-red-600" : "text-gray-900 dark:text-gray-50"
          }`}
        >
          {label}
        </label>
      )}
      <div className="flex w-full max-w-full">
        <div
          className={`flex w-full rounded-3xl border bg-white dark:bg-gray-900 ${
            error ? "border-red-500" : "border-gray-300 dark:border-gray-700"
          }`}
        >
          <input
            type="text"
            id={`${name}-first`}
            autoComplete="off"
            value={firstPart}
            onChange={e => handleChange(e, "first")}
            className="w-1/2 rounded-l-3xl border-0 bg-transparent px-4 py-3 text-sm text-gray-700 focus:outline-none focus:ring-0 dark:text-white"
            placeholder={firstPlaceholder}
          />
          <div className="flex min-w-10 items-center justify-center font-semibold text-gray-700 dark:text-gray-50">
            /
          </div>
          <input
            type="text"
            id={`${name}-second`}
            autoComplete="off"
            value={secondPart}
            onChange={e => handleChange(e, "second")}
            className="w-1/2 rounded-r-3xl border-0 bg-transparent px-4 py-3 text-sm text-gray-700 focus:outline-none focus:ring-0 dark:text-white"
            placeholder={secondPlaceholder}
          />
        </div>
        <input type="hidden" {...register(name, rules)} />
      </div>
      <FormErrorMessage
        label={label || `${firstPlaceholder}/${secondPlaceholder}`}
        error={error}
      />
    </div>
  );
};

export default CustomNoInput;
