import React, { useEffect, useState } from "react";
import {
  Button,
  CustomNoInput,
  DateInput,
  Input,
  MaskInput,
  Pagination,
  Select
} from "../../components";
import { useForm } from "react-hook-form";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useScreenDetector } from "../../hooks/use-screen-detector";
import api from "../../services/api";
import { Link, useLocation } from "react-router-dom";
import { convertDateFormat } from "../../utils/date";
import { sourceTitle } from "../../constants/source";
import { ICTIHAT_UNITS } from "../../constants/search-filters";
import { noValueFormatter } from "../../utils/formatter";
import { sendEvent } from "../../utils/event";

const institutions = [
  {
    label: "Yargıtay",
    value: "yargitay"
  },
  {
    label: "Danıştay",
    value: "danistay"
  },
  {
    label: "Bölge Adliye Mahkemesi",
    value: "uyap"
  },
  {
    label: "Hukuk Mahkemesi",
    value: "uyap"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi",
    value: "danistay"
  },
  {
    label: "KVKK",
    value: "kvkk"
  }
];

const SearchJurisprudence = () => {
  const { isMobile } = useScreenDetector();
  const [showDetailSearch, setShowDetailSearch] = useState(!isMobile);
  const [units, setUnits] = useState(ICTIHAT_UNITS);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({});
  const [firstOpen, setFirstOpen] = useState(true);
  const [isSettingInitialValues, setIsSettingInitialValues] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: page,
    has_next: true,
    has_previous: false,
    page_size: 10,
    total_pages: 1,
    total_results: 0,
    accessible_results: 0,
    is_truncated: false
  });

  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset
  } = useForm();

  const location = useLocation();

  useEffect(() => {
    sendEvent("view_ictihat_search");
  }, []);

  useEffect(() => {
    // Handle incoming search parameters from redirect
    const searchParams = location.state?.searchParams;
    if (searchParams) {
      setIsSettingInitialValues(true);

      // Set form values
      setValue("query", searchParams.query);

      // Handle index selection
      const indexOptions = [
        { value: "yargitay", label: "Yargıtay" },
        { value: "danistay", label: "Danıştay" },
        { value: "uyap", label: "Uyap" }
      ];
      const selectedIndexes =
        searchParams.index
          ?.map(value => indexOptions.find(option => option.value === value))
          .filter(Boolean) || [];

      // First update units based on selected indexes
      const indexes = selectedIndexes.map(item => item.value);
      const updatedUnits = ICTIHAT_UNITS.filter(unit =>
        indexes.includes(unit.institution)
      );
      setUnits(updatedUnits);

      // Then set index and daire values together
      setValue("index", selectedIndexes);

      // Handle daire selection
      if (searchParams.daire && searchParams.daire.length > 0) {
        const selectedDaires = searchParams.daire
          .map(daireName => updatedUnits.find(unit => unit.label === daireName))
          .filter(Boolean);
        if (selectedDaires.length > 0) {
          setValue("daire", selectedDaires);
        }
      }

      // Set other form values
      setValue("esas_no", searchParams.esas_no);
      setValue("karar_no", searchParams.karar_no);

      // Handle date inputs
      if (searchParams.karar_tarih_start) {
        setValue("karar_tarih_start", {
          startDate: new Date(searchParams.karar_tarih_start),
          endDate: new Date(searchParams.karar_tarih_start)
        });
      }
      if (searchParams.karar_tarih_end) {
        setValue("karar_tarih_end", {
          startDate: new Date(searchParams.karar_tarih_end),
          endDate: new Date(searchParams.karar_tarih_end)
        });
      }

      // Trigger search with the provided parameters
      handleSubmit(onSubmit)(searchParams);

      // Clear the location state to prevent re-running the search on page refresh
      window.history.replaceState({}, document.title);

      setIsSettingInitialValues(false);
    }
  }, [location.state]);

  useEffect(() => {
    if (!isSettingInitialValues) {
      const selectedIndexes = watch("index");
      if (selectedIndexes && selectedIndexes.length > 0) {
        const indexes = selectedIndexes.map(item => item.value);
        setValue("daire", []); // Only clear daire when user changes index manually
        setUnits(
          ICTIHAT_UNITS.filter(unit => indexes.includes(unit.institution))
        );
      } else {
        setUnits(ICTIHAT_UNITS);
        setValue("daire", []);
      }
    }
  }, [watch("index"), isSettingInitialValues]);

  const onReset = () => {
    reset({
      query: "",
      index: [],
      daire: [],
      esas_no: "",
      karar_no: "",
      karar_tarih_start: "",
      karar_tarih_end: ""
    });
  };

  const onSubmit = async params => {
    const prepareParams = {
      ...params,
      index:
        params.index?.map(item =>
          typeof item === "string" ? item : item.value
        ) || [],
      daire:
        params.daire?.filter(Boolean).map(item => item.label || item) || [],
      karar_tarih_start: convertDateFormat(params.karar_tarih_start?.startDate),
      karar_tarih_end: convertDateFormat(params.karar_tarih_end?.startDate),
      esas_no: params.esas_no,
      karar_no: params.karar_no
    };

    delete prepareParams.page;

    const { data } = await api.search.searchIctihat({
      ...prepareParams,
      page: 1
    });

    setResults(data.results);
    setPaginationData({
      currentPage: data.pagination.current_page,
      has_next: data.pagination.has_next,
      has_previous: data.pagination.has_previous,
      page_size: data.pagination.page_size,
      total_pages: data.pagination.total_pages,
      total_results: data.pagination.total_results,
      accessible_results: data.accessible_results,
      is_truncated: data.pagination.is_truncated
    });
    setParams(prepareParams);
    setPage(1);
    setFirstOpen(false);
    sendEvent("search_ictihat", { search_term: params.query });
  };

  useEffect(() => {
    if (!firstOpen) {
      const init = async () => {
        const { data } = await api.search.searchIctihat({
          ...params,
          page
        });
        setResults(data.results);
        setPaginationData({
          currentPage: data.pagination.current_page,
          has_next: data.pagination.has_next,
          has_previous: data.pagination.has_previous,
          page_size: data.pagination.page_size,
          total_pages: data.pagination.total_pages,
          total_results: data.pagination.total_results,
          accessible_results: data.accessible_results,
          is_truncated: data.pagination.is_truncated
        });
      };

      init();
    }
  }, [page]);

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <section className={"flex min-w-full flex-col "}>
        <div className={"flex gap-4"}>
          <Input
            name="query"
            error={errors.query}
            rules={{}}
            register={register}
            className="mb-4"
            placeholder="Aranacak kelimeyi girin..."
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
          />
        </div>
        {showDetailSearch && (
          <>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <Select
                options={institutions}
                name="index"
                error={errors.index}
                rules={{}}
                isMulti={true}
                control={control}
                placeholder="Kurum"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
              <Select
                options={units}
                name="daire"
                error={errors.daire}
                rules={{}}
                isMulti={true}
                control={control}
                placeholder="Daire"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            </div>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <CustomNoInput
                name="esas_no"
                // @ts-ignore
                error={errors.esas_no}
                register={register}
                setValue={setValue}
                className="mb-5"
                firstPlaceholder="Esas No (Yıl)"
                secondPlaceholder="Esas No (Sıra)"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />

              <CustomNoInput
                name="karar_no"
                // @ts-ignore
                error={errors.karar_no}
                register={register}
                setValue={setValue}
                className="mb-5"
                firstPlaceholder="Karar No (Yıl)"
                secondPlaceholder="Karar No (Sıra)"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            </div>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <DateInput
                name="karar_tarih_start"
                // @ts-ignore
                error={errors.karar_tarih_start}
                control={control}
                placeholder="Karar Tarihi (Başlangıç)"
                maxDate={new Date()}
                rules={{}}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
              <DateInput
                name="karar_tarih_end"
                // @ts-ignore
                error={errors.karar_tarih_end}
                control={control}
                placeholder="Karar Tarihi (Bitiş)"
                maxDate={new Date()}
                rules={{}}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            </div>
          </>
        )}
        <div className={"flex items-center justify-end gap-4 md:flex-col"}>
          <button
            className="flex items-center gap-1 font-display text-sm text-primary-600 transition-colors duration-200 ease-in-out hover:text-primary-700 dark:text-primary-500 dark:hover:text-primary-400"
            onClick={() => setShowDetailSearch(!showDetailSearch)}
          >
            Detaylı aramayı {showDetailSearch ? "kapat" : "göster"}
            {!showDetailSearch ? (
              <IconChevronDown size={20} />
            ) : (
              <IconChevronUp size={20} />
            )}
          </button>
          <div className={"flex justify-end gap-4 md:w-full"}>
            <Button
              onClick={() => onReset()}
              isFullWidth={isMobile}
              variant="secondary"
              className="font-display"
            >
              Sıfırla
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isFullWidth={isMobile}
              className="font-display"
            >
              Ara
            </Button>
          </div>
        </div>
      </section>
      <section className="my-10 flex flex-col gap-2">
        {results.length > 0 && (
          <div className="mb-3 font-sans text-sm text-gray-700 dark:text-gray-500">
            Toplam{" "}
            <strong>
              {paginationData.total_results.toLocaleString("tr-TR")}
            </strong>{" "}
            sonuç bulundu
          </div>
        )}
        {results.map((result, index) => (
          <Link
            key={index}
            className={
              "flex cursor-pointer flex-col rounded-2xl border border-gray-200 bg-white p-4 transition duration-300 ease-in-out hover:bg-gray-25 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-950"
            }
            to={`/doc/ictihat?s3Url=${result.s3_url_json}`}
          >
            <div className={"flex flex-col gap-2"}>
              <div className="text-medium font-title font-semibold text-gray-800 dark:text-white">
                {sourceTitle[result.source]} {result.daire} Kararı
              </div>
              <div className="flex gap-3 font-sans text-sm text-gray-700 md:flex-col md:gap-1 dark:text-gray-500">
                {result.esasNo && (
                  <div className="flex gap-1">
                    <span>Esas No:</span>
                    <strong className="font-medium">{result.esasNo}</strong>
                  </div>
                )}
                <div className="flex gap-1">
                  <span>Karar No:</span>
                  <strong className="font-medium">{result.kararNo}</strong>
                </div>
                <div className="flex gap-1">
                  <span>Karar Tarihi:</span>
                  <strong className="font-medium">{result.kararTarihi}</strong>
                </div>
              </div>
              {result.highlights && (
                <div className="flex flex-col gap-2">
                  {result.highlights.kararMetni?.length > 0 && (
                    <div
                      className="font-sans text-sm text-gray-600 dark:text-gray-300"
                      dangerouslySetInnerHTML={{
                        __html: result.highlights.kararMetni.join(" ... ")
                      }}
                    />
                  )}
                  {result.highlights.kunye?.length > 0 && (
                    <div
                      className="font-sans text-sm text-gray-500 dark:text-gray-400"
                      dangerouslySetInnerHTML={{
                        __html: result.highlights.kunye.join(" ... ")
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </Link>
        ))}
        {results.length > 0 && (
          <Pagination
            totalPages={paginationData.total_pages}
            currentPage={page}
            pageSize={paginationData.page_size}
            setCurrentPage={pageValue => setPage(pageValue)}
            totalResults={paginationData.total_results}
            accessibleResults={paginationData.accessible_results}
            isTruncated={paginationData.is_truncated}
          />
        )}
        {!firstOpen && results.length === 0 && (
          <div className="mt-4 flex flex-col items-center justify-center gap-1 text-gray-800 dark:text-white">
            <div className="font-display text-lg font-semibold">
              Sonuç bulunamadı.
            </div>
            <div className="font-sans text-base text-gray-600 dark:text-gray-200">
              Arama yaptığınız kriterlere göre herhangi bir sonuç bulunamadı.
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default SearchJurisprudence;
